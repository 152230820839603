/**
Basic Custom Styles
 */
 body{
    cursor: default;
 }
 button, [role="button"], a, button span  {
    cursor: pointer;
}
input{
    cursor: text;
}
.my-facebook-button-class{
    padding: 10px;
    background-color: rgba(63,81,181,var(--tw-bg-opacity));
    color: #fff;
    border-radius: 2px;
    width: 100%;
}
.my-google-button-class {
    display: inline-grid !important;
    color: #fff !important;
    background-color: #f73e2f !important;
}
.inFlex {
    display: -webkit-inline-box;
}

.birth-date-content .rmdp-input {
    height: 52px !important;
    width: 276px;
    padding-left: 14px;
    margin: 0;
}
.birth-date-content.portfolio-date .rmdp-input, .birth-date-content.portfolio-date .rmdp-container {
    width: 100%;
}
.txt-ellipse {
    white-space: nowrap;
    width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 10px;
    padding-right: 10px;
}

p, h1, h2, h3, h4, h5, h6 {
    cursor: default;
}

.top-custom-border {
    border-top: 1px solid #eee;
}
.top-custom-border .mat-focus-indicator{
    transition: 0.3s ease;
}
.top-custom-border .mat-focus-indicator:hover{
    background-color: rgba(63,81,181,var(--tw-bg-opacity));
    color: #fff;
}
.registration-form{
    width: 600px;
}
.add-watchlist-dialog,.add-post-category,.add-post-category-content,.add-post-category form, .add-type-activity-content,.add-type-activity,
.add-type-activity form, .add-privacy-policy form, .add-privacy-Policy-content, .add-privacy-policy {
    overflow: unset !important;
}
.add-watchlist-dialog header, .add-post-category header, .addtype-activity header, .add-privacy-policy header{
    border-radius: 16px 16px 0 0;
}

.image-background{
    background: #f6f7f9;
}

.text-ellipsis{
    text-overflow: ellipsis;
}

.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
}
.mySwiper .swiper-pagination{
    position: unset;
}

.custom_progressbar .MuiLinearProgress-barColorPrimary-450{
    background-color: rgba(63,81,181,var(--tw-bg-opacity));
}

.css-1uccc91-singleValue{
    top: 58%;
}
.portfolio-dialog label{
    z-index: 0;
}

.progress-success div{
    background-color: rgb(56 142 60);
}

.progress-orange div{
    background-color: rgb(255 159 0);
}

.progress-red div{
    background-color: rgb(255 97 97);
}
.rating-overview{
    width: 230px;
}
.line-clamp-2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}
a:not([role=button]):hover {
    text-decoration: none;
}

.widget a:not([role=button]){
    color: rgba(97, 97, 97, var(--tw-text-opacity));
    position: relative;
    padding-bottom: 3px;
    cursor: pointer;
}
.widget a:after{
    border-bottom: 1px solid #ffa500;
    height: 1px;
    width: 0;
    position: absolute;
    bottom: 0;
    content: '';
    left: 0;
    transition: 0.3s ease;
}
.widget a:not([role=button]).active{
    color: rgba(63,81,181,var(--tw-text-opacity));
}
.widget a.active:after{
    width: 100%;
}
.link-color{
    color: #22d3ee;
}
.privacy-dialog .MuiDialog-paperWidthSm-13{
    max-width: unset;
    width: 80%;
}
@media(min-width: 768px){
    .agreements .agree-content{
        margin-left: 300px;
    }
}

.botdetailtoken input{
    cursor: pointer !important;
}
tooltip{
    background-color: #000;
}
.cropped-image {
    height: 200px;
    width: auto;
  }
  
  .cropped-image-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
  
  ._coverImage-holder  {
    padding: 25px 40px;
    background-color:black;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px;
  }
  
  
  .container {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  
  .crop-container {
    height: 400px;
    width: 400px;
  }
  
  .controls {
    display: flex;
    flex-direction: column;
    width: 400px;
    position: absolute;
    bottom: -15px;
  }
  .custom-tab button[aria-selected="true"] {
    background-color: #ffa500;
    border-color: #ffa500;
    color: #000;
}
.custom-tab span{
    background-color: transparent;
}

.policy-name label{
    z-index: 0;
}
@media(min-width:1200px){
.activity-clear li:hover .material-icons{
    opacity: 1 !important;
}
.activity-clear li:hover{
    padding-right: 40px !important;
    cursor: pointer;
}
.activity-clear li:hover .relative{
margin-left: -65px !important;
}
.activity-clear li,.activity-clear li .relative{
    transition: 0.3s ease;
}
}
@media(max-width:1199px){
    .activity-clear li .relative .text-red{
        opacity: 1 !important;
        position: unset;
        margin-left: 10px;
    }
}
.css-yk16xz-control{
    height: 50px;
    overflow: scroll;
}
.css-1hb7zxy-IndicatorsContainer{
    height: 50px;

}
.css-1hb7zxy-IndicatorsContainer,.css-yk16xz-control,.css-1pahdxg-control{
    height: 50px;
    overflow: scroll;

}
.invite_member .css-yk16xz-control,.invite_member .css-1hb7zxy-IndicatorsContainer,.css-yk16xz-control,.css-1pahdxg-control{
    height: unset;
    overflow: auto;
}
.invite_member .css-1hb7zxy-IndicatorsContainer{
    height: unset;

}
.demo-editor{
    min-height: 100px !important;z-index: 0;
}
.DraftEditor-editorContainer{
    z-index: 0 !important;
}
.privacy-cutom-dialog blockquote, .privacy-cutom-dialog dl, .privacy-cutom-dialog dd, .privacy-cutom-dialog .privacy-cutom-dialog .privacy-cutom-dialog h1, .privacy-cutom-dialog h2,
.privacy-cutom-dialog h3, .privacy-cutom-dialog h4, .privacy-cutom-dialog h5, .privacy-cutom-dialog h6, .privacy-cutom-dialog hr, .privacy-cutom-dialog figure, .privacy-cutom-dialog p, .privacy-cutom-dialog pre {
    margin: revert;
    color: unset;
}
h6.privacy-dialog-title {
    margin: 0;
}
.comment-box{
    padding-top: 88px;
    padding-bottom: 65px;
}
.news-box{
    padding-bottom: 65px;
}
.policy-login{
    padding-bottom: 0 !important;
}
.funds-drop div{
    background-color: transparent !important;
}

.break-word{
    word-wrap: break-word;
}
.iconContainer{
    padding: 0;
}
.social-share [class*="makeStyles-copyUrl-"]{
    color: #000;
}
.social-share [class*="makeStyles-copyIcon-"]{
    color: #fff;
    background-color: #3F51B5;
    height: 36px;
    align-items: center;
    border-radius: 4px;
    vertical-align: middle;
    display: flex;
    margin: 0 8px;
    cursor: pointer;
}
.social-share [class*="makeStyles-copyIcon-"] p{
    cursor: pointer;
}
.social-share [class*="makeStyles-copyContainer-"]{
    border: 1px solid lightgray;
    color: black;
    padding: 8px;
    height: 52px;
    background: rgba(255,255,255,1);
}
.social-share [class*="makeStyles-container-"]{
    padding: 0 12px 12px;
}
.social-share [class*="makeStyles-iconContainer-"]{
    padding: 0 0 15px 0;
}
.profile-bio{
    max-width: 500px;
    word-break: break-word;
}
.invite-team-list li:last-child{
    margin-bottom: 0;
}
@media(max-width: 767px){
    .registration-form{
        width: 100%;
    }
    .react-tel-input .form-control,.birth-date-content .rmdp-input{
        width: 100% !important;
    }
    .birth-date-content .rmdp-container{
        display: block !important;
    }
    .top-banner{
        background-size: auto !important;
    }
}
.news-title, .news-title:hover {
    background: #fff !important;
    text-decoration: none;
    border-bottom: none !important;
}

#menu-selectmember [class*="MuiPopover-paper-"]{
    height: 200px;
    margin-top: 90px;
}
.bot-top fieldset{
    border: none;
}
.bot-top .bot-select{
    border: none;
}
.bot-list-value span:last-child span{
    display: none;
}
.bot-dashbord::after {
    content: '';
    position: absolute;
    width: 300px;
    height: 300px;
    background-color: #fff5e2;
    text-align: center;
    border-radius: 100% 0 0 100%;
    top: -53px;
    right: -90px;
}
.w-220{
    width: 220px;
}
.custom-page-height{
    height: calc(100vh - 200px);
}
.team-page-height{
    height: calc(100vh - 250px);
}
.agreements ul li a.active{
    color:#ffa500 !important;
}
.team-button{
    border: 1px solid;
}
/* document css Start */
.f-24{
    font-size: 24px;
} 
.f-20{
    font-size: 20px;
}
.mx-1250{
    max-width: 1250px;
} 
.left-doc a:hover span,.left-doc li:hover span{
    color:#ffa500;
}
.left-doc a.active span,.left-doc a.active{
    color:#ffa500;
}

/* .right-doc [class*="MuiStepIcon-active-"] {
    color: #ffa500;
}
.right-doc [class*="MuiStepIcon-active-"] [class*="MuiStepIcon-text-"],.right-doc [class*="MuiStepButton-root-"]:hover [class*="MuiStepIcon-root-"] [class*="MuiStepIcon-text-"] {
    fill: #000;
}
.right-doc [class*="MuiStepButton-root-"]:hover [class*="MuiStepIcon-root-"] {
    color: #ffa500;
} */
/* document css End */
/* landing page css Start */
.top-banner {
    height: calc(100vh - 122px);
}
.create-bot-2 h2{
    max-width: 430px;
}
.create-bot-2 p{
    max-width: 592px;
}
/* landing page css end */
.about-content p{
    margin-bottom: 15px;
}
.help-heading div{
    padding: 0;
    margin: 0;
    margin-top: 0 !important;
    width: 100%;
}
.help-heading div:last-child {
    position: absolute;
    right: 0;
    width: auto;
    top: 0;
}
.help-heading{
    min-height: unset !important;
}